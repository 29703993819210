import keyBy from 'lodash-es/keyBy';
import chunk from 'lodash-es/chunk';
import last from 'lodash-es/last';
import Item from './components/Item';
import PracticeConfiguration from './components/PracticeConfiguration';
import MemorizeItem from './components/MemorizeItem';
import backgroundImage from './images/background.png';
import itemsRaw from './items-data';

const images = require.context('./images/items', true, /\.(png|jpg)$/);

function getImageUrl(name) {
    try {
        return images(`./${name}.jpg`);
    } catch (err) {
        return null;
    }
}

export default function generateConfig({ language }) {
    const items = itemsRaw[language].map(([numbershape, word]) => {
        const id = numbershape.split('-')[0];
        return {
            id,
            label: id,
            numbershape,
            word,
            image: getImageUrl(id)
        };
    });
    const itemsMap = keyBy(items, item => item.id);
    return {
        items: itemsMap,
        itemIds: items.map(item => item.id),
        itemGroups: chunk(items, 11).map((sublist, index) => {
            return {
                index,
                label: `${sublist[0].id}-${last(sublist).id}`,
                itemIds: sublist.map(x => x.id)
            };
        }),
        BrowseItem: Item,
        MemorizeItem,
        PracticeConfiguration,
        backgroundImage,
        systemId: 'single',

        getImagesInGroup(group) {
            return group.itemIds.map(itemId => itemsMap[itemId].image);
        }
    };
}
