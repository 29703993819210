export default {
    en: [
        ['0', 'wheel'],
        ['1', 'forefinger'],
        ['2', 'swan'],
        // ['3-bra', 'bra'],
        ['3', 'mask'],
        ['4', 'bell'],
        ['5', 'hook'],
        ['6', 'paper clip'],
        ['7', 'cliff'],
        ['8', 'snowman'],
        ['9', 'sunflower'],
        ['10', 'speedometer']
    ],
    no: [
        ['0', 'hjul'],
        ['1', 'pekefinger'],
        ['2', 'svane'],
        // ['3-bra', 'BH'],
        ['3', 'maske'],
        ['4', 'bjelle'],
        ['5', 'krok'],
        ['6', 'binders'],
        ['7', 'klippe'],
        ['8', 'snømann'],
        ['9', 'solsikke'],
        ['10', 'speedometer']
    ]
};
